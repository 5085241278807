import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { TYPED_DATA_TYPE, IpaTypeModel } from '@shared/models';
import { routeToEvent } from '@events/routes';
import { Forms } from '@shared/utils';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { EventModalsService, EventDetailFormService, AuditDetailFormService } from '@events/services';
import { EventDetailModel, PositionsGridModel, PositionsTotalGridModel, EventPositionInputModel, toFilterDateString, AuditDetailModel } from '@events/models';
import { EventsService } from '@shared/services/events.service';
import { EVENT_CONSTANTS as E } from '@events/configs/event-constants';
import { PermissionService } from '@shared/services/permission.service';
import { ValuationDetailModel } from '@valuation/models';
import { LocationService } from '@shared/services/location.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { ValuationService } from '@shared/services/valuation.service';
import { EventsPositionService } from '@shared/services/events-position.service';
import { EventPositionEntitlementDto } from '@apis/backend/payments';
import { PositionContainerComponent } from '..';
import { SwiftMessageResponseDto, EcashPaymentDetailsResponse, PoolFactorHistoryResponseListDto } from '@apis/backend/events';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
  providers: [EventModalsService, { provide: FormServiceInjectionToken, useExisting: EventDetailFormService }, { provide: LabelRootTranslateInjectionToken, useValue: 'events.form' }]
})
export class EventDetailComponent extends BaseFormComponent<EventDetailModel, EventDetailFormService> {
  @ViewChild(PositionContainerComponent) positionContainerComponent!: PositionContainerComponent;

  public EVENT_REDEMPTION_TYPE: TYPED_DATA_TYPE = 'EVENT_REDEMPTION_TYPE';
  public INTERVENTION_TYPE: TYPED_DATA_TYPE = 'INTERVENTION_TYPE';
  public EVENT_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'EVENT_WORKFLOW_STATUS';
  public paymentCcys: string[] | undefined | null;
  displayRedemptionFieldsInTypes = ['REDM', 'MCAL'];
  poolFactorAndPartialRedemption = ['REDM', 'MCAL', 'INTR', 'DVCA'];
  denominationEventTypes = ['PRED', 'DVCA'];
  public redemptionTypesDropDown: string[] = [];
  private redeemableInSecuritiesTrue = ['C', 'D', 'T'];
  private redeemableInSecuritiesFalse = ['C'];
  private redemptionInShares = ['D', 'T'];
  eventStatusDropDownValues: (string | undefined)[] = [];
  private internationalISIN = [E.leiClearstreamBankingSALuxembourg, E.leiEuroclearBankBelgium];
  canModifyEvents = false;
  public shutdown$ = new Subject<void>();
  public eventId: number | undefined;
  public swiftsEventId: number | undefined;
  disableCancelForStatus: string[] = ['FIXING DATE TO BE REACHED', 'EVENT TO BE INPUT', 'VALUATION CHASED', 'VALUATION IN REPAIR', 'EVENT TO BE VALIDATED', 'EVENT CANCELLED', 'EVENT CLOSED', 'DELETED'];
  compareEventValuesForStatus = ['VALUATION IN REPAIR', 'EVENT TO BE VALIDATED'];
  displaySendToValidationForStatus = ['EVENT TO BE INPUT', 'VALUATION IN REPAIR', 'FIXING DATE TO BE REACHED', 'NOTIFICATION BLOCKED', 'EXCHANGE_TO_DO', 'CERTIFICATION TO DO'];
  disableModifyForEvents = ['EVENT CANCELLED', 'EVENT CLOSED', 'DELETED'];
  fourEyeCheckValidation = [E.status.eventToBeValidated, E.status.exchangeToValidate, E.status.certificationToValidate];
  disableCancel = false;
  isValuationLinked = false;
  valuationId: number | undefined;
  outstandingNominal: string | undefined;
  isRedemptionInShares = false;
  isInternationalISIN = false;
  downloadRof: string | null = null;
  positionsLoaded?: Promise<boolean>;
  eventLoaded?: Promise<boolean>;
  pageType: string | undefined;
  public mismatchColor = 'red !important';
  public dcfColorMismatched: string | null = '';
  public denominationValueMismatched: string | null = '';
  public denominationCcyMismatched: string | null = '';
  public redmTypeMismatched: string | null = '';
  public redmRateMismatched: string | null = '';
  public startDateValueMismatched: string | null = '';
  public endDateValueMismatched: string | null = '';
  public amountPerDenominationValueMismatched: string | null = '';
  public currentUser: string | null = '';
  public latestStatusUpdatedUser = '';
  public displayValidateAndReject = false;
  public tradingType: string | null | undefined = '';
  public positions: PositionsGridModel[] = [];
  public positionsTotal: PositionsTotalGridModel[] = [];
  public positionData: EventPositionEntitlementDto[] = [];
  public eventPositionInput: EventPositionInputModel | undefined;
  public reqFundsDateModificationAlert = false;
  public beginEndDateAlert = false;
  public preAdviseReqOfFundModificationAlert = false;
  public preAdviseNotificationModificationAlert = false;
  public valueDateModificationAlert = false;
  public recordValueDateAlert = false;
  public disableSaveButton = false;
  public payingType: string | undefined;
  public commonServiceProviderLeiCode: string | undefined;
  public commonDepLeiCode: string | undefined;
  public listingType: string | undefined;
  public enableEventStatus = false;
  public isCspSgLux = false;
  public instrumentStatus: string | undefined;
  displaySwifts = false;
  displayPoolFactors = false;
  poolFactorData?: PoolFactorHistoryResponseListDto;
  displayEcashPayment = false;
  swiftData?: SwiftMessageResponseDto;
  eCashPaymentsData?: EcashPaymentDetailsResponse;
  private amountPerDenom: number | null | undefined;
  private newPoolFactor: number | undefined;
  private recordDateReached = false;
  public auditData?: AuditDetailModel;

  constructor(
    private eventModalsService: EventModalsService,
    private eventService: EventsService,
    private readonly eventDetailFormService: EventDetailFormService,
    private readonly auditDetailFormService: AuditDetailFormService,
    private readonly valuationService: ValuationService,
    private readonly positionService: EventsPositionService,
    private readonly toastManagerService: ToastManagerService,
    private readonly permissionService: PermissionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly locationService: LocationService
  ) {
    super(eventDetailFormService, 'events.creation');
  }
  ngOnInit(): void {
    this.permissionService.canCreateEvents$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canModifyEvents: boolean) => {
        this.canModifyEvents = canModifyEvents;
      }
    });
    this.permissionService.user$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (user: string | null) => {
        this.currentUser = user;
      }
    });

    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        const data = routeData['event'] as [EventDetailModel | null, ValuationDetailModel | null];
        const eventDetail = data.length > 0 ? data[0] : (data as EventDetailModel);
        const valuationData = data[1];
        this.eventId = routeParams.id;
        if (valuationData && valuationData !== null) {
          this.isValuationLinked = true;
          this.valuationId = valuationData.valuationRef;
        }

        const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';
        this.pageType = pageType as string;

        if (pageType === 'consult' && this.eventId && eventDetail) {
          this.eventDetailFormService.reset();
          this.eventDetailFormService.setInitialValue(eventDetail);
          this.amountPerDenom = eventDetail.amountPerDenomination;
          this.newPoolFactor = eventDetail.newPoolFactor;
          this.tradingType = eventDetail.tradingMethodIdentifier;
          const clearingSystem = eventDetail.clearingSystems ? eventDetail.clearingSystems.map(x => x.leiCode) : undefined;
          this.isInternationalISIN = this.internationalISIN.filter(x => clearingSystem?.includes(x)).length > 0;
          const redemptionType = this.formService.rawValue('redemptionType')?.valueDescription;
          this.isRedemptionInShares = redemptionType ? this.redemptionInShares.includes(redemptionType) : false;
          this.getPositionData(eventDetail);
          this.setRoleBasedMandatoryFields();
          this.auditData = {
            eventValidatedBy: eventDetail.eventValidatedBy,
            eventValidatedAt: eventDetail.eventValidatedAt,
            eventSentforValidationBy: eventDetail.eventSentforValidationBy
          };
          this.eventService.getEventStatusUpdatedUserDetails(Number(eventDetail?.eventId)).subscribe(result => {
            this.latestStatusUpdatedUser = result.userUpdated!;
            this.displayValidateAndReject = eventDetail.eventStatus?.valueDescription ? this.fourEyeCheckValidation.includes(eventDetail.eventStatus.valueDescription) && this.currentUser !== this.latestStatusUpdatedUser : false;
          });
        }

        this.paymentCcys = this.eventDetailFormService.rawValue().paymentCurrencys;
        const eventType = this.eventDetailFormService.rawValue().eventType;
        const eventStatus = this.eventDetailFormService.rawValue().eventStatus?.valueDescription;
        const multiplePmtCurrencies = this.eventDetailFormService.rawValue().multiplePmtCurrencies;
        this.redemptionTypesDropDown = eventDetail?.redeemableInSecurities ? this.redeemableInSecuritiesTrue : this.redeemableInSecuritiesFalse;
        this.eventStatusDropDown();
        this.eventLoaded = Promise.resolve(true);
        this.eventDetailFormService.setPaymentCurrencyFields(multiplePmtCurrencies!);
        this.eventDetailFormService.setFormMode(pageType);
        this.eventDetailFormService.eventLockFields();
        switch (eventType) {
          case E.eventTypes.INTR: {
            this.eventDetailFormService.setMandatoryIntrFields();
            // to be implemented when event option type for intr is enabled
            // if (pageType === 'edit') {
            //   this.displayRedemtionTypeBasedFields();
            // }
            if (eventDetail && valuationData) {
              this.getIntrValuesFromValuation(eventDetail, valuationData);
              if (eventStatus && this.compareEventValuesForStatus.includes(eventStatus)) {
                this.highlightMismatchedDataForINTR(eventDetail, valuationData);
              }
            }
            break;
          }
          case E.eventTypes.REDM:
          case E.eventTypes.MCAL: {
            this.eventDetailFormService.setMandatoryRedmAndMcalFields();
            if (pageType === 'edit') {
              this.displayRedemtionTypeBasedFields();
              const tradingType = this.formService.rawValue('tradingMethodIdentifier');
              this.eventDetailFormService.addRedemtionPriceUnitValidators(tradingType);
            }
            if (eventDetail && valuationData) {
              this.getRedmMcalValuesFromValuation(eventDetail, valuationData);
              if (eventStatus && this.compareEventValuesForStatus.includes(eventStatus)) {
                this.highlightMismatchedDataForREDMAndMCAL(eventDetail, valuationData);
              }
            }
            break;
          }
          case E.eventTypes.PRED: {
            this.eventDetailFormService.setMandatoryPredFields();
            break;
          }
          case E.eventTypes.DVCA: {
            this.eventDetailFormService.setMandatoryDvcaFields();
            break;
          }
        }
      });
  }

  private getPositionData(eventDetail: EventDetailModel) {
    let draftPosition: EventPositionEntitlementDto | undefined;
    this.positionService.getPositions(this.eventId!).subscribe(response => {
      if (response?.results && response.results.length > 0) {
        this.positionData = response.results;
        draftPosition = this.positionData.find(x => x.interventionType === undefined);
        if (draftPosition && !draftPosition.isQtyUpdated) {
          this.createPosition(eventDetail);
        } else {
          this.setPositions(eventDetail, draftPosition?.isQtyUpdated);
        }
      } else {
        this.createPosition(eventDetail);
      }
    });
  }

  private createPosition(eventDetail: EventDetailModel) {
    const isin = this.formService.rawValue('isin')!;
    const dtoPosition = {
      isin: isin,
      eventId: this.eventId!,
      pullFromCommonDepository: this.isInternationalISIN
    };
    this.positionService.createPosition(dtoPosition).subscribe(pos => {
      const positionDataDraftVersion = this.positionData.find(x => x.interventionType === undefined);
      if (pos?.results && pos.results.length > 0) {
        const posDataDraftVersion = pos.results.find(x => x.interventionType === undefined);
        if (this.positionData.length === 1 && posDataDraftVersion) {
          this.positionData = pos.results;
        } else if (positionDataDraftVersion && posDataDraftVersion) {
          const draftIndex = this.positionData.findIndex(x => x.interventionType === undefined);
          this.positionData[draftIndex] = posDataDraftVersion;
        } else {
          this.positionData = pos.results;
        }
        this.setPositions(eventDetail, positionDataDraftVersion?.isQtyUpdated);
      } else {
        this.setPositions(eventDetail, positionDataDraftVersion?.isQtyUpdated);
      }
    });
  }

  private setPositions(eventDetail: EventDetailModel, isQtyUpdated: boolean | undefined) {
    this.getRofData(eventDetail.principalPayingAgent?.leiCode, eventDetail.transferMode);
    this.eventPositionInput = {
      eventId: this.eventId,
      isInternationalISIN: this.isInternationalISIN,
      isRedemptionInShares: this.isRedemptionInShares,
      outstandingNominal: eventDetail?.outStandingNominalAsString,
      isSGLuxNotCommonDepOrServiceProvider: !(eventDetail?.commonDepositary?.leiCode === E.leiCodeSocieteGeneraleLuxembourg || eventDetail?.commonServiceProvider?.leiCode === E.leiCodeSocieteGeneraleLuxembourg),
      tradingType: this.tradingType!,
      enableGeneratePreadvise: this.enableGeneratePreadvise(),
      enableGenerateRequestOfFunds: this.enableGenerateRequestOfFunds(),
      eventTypeValue: eventDetail.eventType!,
      isQtyUpdated: isQtyUpdated,
      paymentDate: eventDetail.paymentDate,
      fundArrivalDate: eventDetail.timingFundArrivalDate,
      paymentCcy: eventDetail.paymentCurrencys,
      eventStatus: eventDetail.eventStatus?.valueId,
      eocAdjustedQuantity: eventDetail.eocAdjQty,
      clsAdjustedQuantity: eventDetail.clsAdjQty,
      totalAdjustedQuantity: eventDetail.totalAdjQty
    };
  }

  releasePayment() {
    const modal = this.eventModalsService.openReleasePaymentModal(this.positions, this.positionsTotal, this.eventPositionInput);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.confirmReleasePayment();
    });
  }

  onAuditClick() {
    this.eventModalsService.openAuditDetailsModal(this.auditData);
  }

  private confirmReleasePayment() {
    if (this.eventId !== null) {
      const eventId = this.eventId;
      this.eventService.updateEventStatus(Number(eventId), 20).subscribe(result => {
        if (result) {
          this.router.navigateByUrl('/events/consult', { skipLocationChange: true }).then(() => {
            this.router.navigate(routeToEvent(eventId, 'consult'));
            this.toastManagerService.toastSuccess('toasts.events.releasePayment.title', 'toasts.events.releasePayment.message', undefined, result);
          });
        }
      });
    }
  }

  positionsGridData(positionGridData: { positions: PositionsGridModel[]; positionsTotal: PositionsTotalGridModel[] }) {
    if (positionGridData) {
      this.positions = positionGridData.positions;
      this.positionsTotal = positionGridData.positionsTotal;
    }
  }

  displaySwiftMessage(displayNotificationList: boolean) {
    if (displayNotificationList) {
      this.eventService.getSwifts(this.eventId!).subscribe(result => {
        if (result) {
          this.displaySwifts = true;
          this.swiftData = result;
        } else {
          this.toastManagerService.toastWarning('toasts.events.notificationList.title', 'toasts.events.notificationList.message', undefined);
        }
      });
    }
  }

  displayPoolFactor(displayPoolFactor: boolean) {
    if (displayPoolFactor) {
      const instrumentId = this.eventDetailFormService.rawValue().ipa;
      this.eventService.getPoolFactor(instrumentId!).subscribe(result => {
        if (result?.factorHistorySearchResponseDtos) {
          this.displayPoolFactors = true;
          this.poolFactorData = result;
        } else {
          this.toastManagerService.toastWarning('toasts.events.poolFactorList.title', 'toasts.events.poolFactorList.message', undefined);
        }
      });
    }
  }

  displayEcashPayments(displayEcashPayments: boolean) {
    if (displayEcashPayments) {
      this.eventService.getEcashPayments(this.eventId!).subscribe(result => {
        if (result) {
          this.displayEcashPayment = true;
          this.eCashPaymentsData = result;
        } else {
          this.toastManagerService.toastWarning('toasts.events.eCashPaymentList.title', 'toasts.events.eCashPaymentList.message', undefined);
        }
      });
    }
  }

  enableGenerateRequestOfFunds(): boolean {
    const eventStatus = this.eventDetailFormService.rawValue().eventStatus?.valueDescription;
    switch (eventStatus) {
      case E.status.notificationSent: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          return true;
        }
        break;
      }
      case E.status.eventValidated: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg && this.commonServiceProviderLeiCode !== E.leiCodeSocieteGeneraleLuxembourg && this.commonDepLeiCode !== E.leiCodeSocieteGeneraleLuxembourg) {
          return true;
        }
        break;
      }
    }
    return false;
  }

  enableGeneratePreadvise(): boolean {
    const eventStatus = this.eventDetailFormService.rawValue().eventStatus?.valueDescription;
    switch (eventStatus) {
      case E.status.preAdviseBlocked: {
        if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          return true;
        }
        break;
      }
      case E.status.notificationSent: {
        if (this.payingType !== E.leiCodeSocieteGeneraleLuxembourg && (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) {
          return true;
        }
        break;
      }
      case E.status.requestOfFundsSent:
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg && (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) {
          return true;
        }
        break;
    }
    return false;
  }

  private getRofData(principalPayingAgent: string | undefined, transferMode: string | undefined) {
    const downloadRof = principalPayingAgent === E.leiCodeSocieteGeneraleLuxembourg && transferMode === 'e-Mail';
    if (downloadRof) {
      this.eventService.getRofPaymentDetails(Number(this.eventId!)).subscribe(response => {
        if (response && response.requestOfFundsDtoLists && response.requestOfFundsDtoLists?.length > 0) {
          this.eventPositionInput!.downloadRof = response.requestOfFundsDtoLists[0].sgdocId || null;
        }
        this.positionsLoaded = Promise.resolve(true);
      });
    } else {
      this.positionsLoaded = Promise.resolve(true);
    }
  }

  savePositions() {
    this.recordDateReached = this.positionData.findIndex(x => x.version === 4) !== -1;
    let recalculate = this.amountPerDenom !== this.eventDetailFormService.rawValue().amountPerDenomination && this.recordDateReached;
    const eventType = this.eventDetailFormService.rawValue().eventType;
    if (eventType === E.eventTypes.PRED) {
      recalculate = this.newPoolFactor !== this.eventDetailFormService.rawValue().newPoolFactor && this.recordDateReached;
    }
    this.positionContainerComponent.saveModifiedRows(recalculate);
  }

  eventStatusDropDown() {
    const eventStatus = this.eventDetailFormService.rawValue().eventStatus?.valueDescription;
    this.enableEventStatus = false;
    switch (eventStatus) {
      case E.status.eventToBeInput: {
        this.enableEventStatus = true;
        this.eventStatusDropDownValues = [eventStatus, E.status.valuationInRepair, E.status.notificationBlocked];
        break;
      }
      case E.status.valuationInRepair:
      case E.status.fixingDateToBeReached: {
        this.enableEventStatus = true;
        this.eventStatusDropDownValues = [eventStatus, E.status.notificationBlocked];
        break;
      }
      case E.status.notificationBlocked: {
        this.enableEventStatus = true;
        this.eventStatusDropDownValues = [eventStatus, E.status.fixingDateToBeReached, E.status.eventToBeInput, E.status.valuationInRepair];
        break;
      }
      case E.status.notificationSent: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg && (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked, E.status.requestOfFundsBlocked];
        } else if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked];
        }
        break;
      }
      case E.status.requestOfFundsSent: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg && (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked, E.status.paymentBlocked];
        } else if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked];
        } else if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.paymentDone, E.status.paymentBlocked];
        }
        break;
      }
      case E.status.preAdviseSent: {
        if ((this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) && this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.paymentDone, E.status.paymentBlocked];
        }
        break;
      }
      case E.status.paymentToBeInput: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.paymentDone, E.status.paymentBlocked];
        }
        break;
      }
      case E.status.paymentBlocked: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.paymentDone, E.status.paymentToBeInput];
        }
        break;
      }
      case E.status.preAdviseBlocked: {
        this.enableEventStatus = true;
        this.eventStatusDropDownValues = [eventStatus, E.status.generatePreAdvise];
        break;
      }
      case E.status.markDownPending:
      case E.status.markDownDone:
      case E.status.paymentDone: {
        this.enableEventStatus = true;
        this.eventStatusDropDownValues = [eventStatus, E.status.eventClosed];
        break;
      }
      case E.status.notificationInRepair: {
        if ((this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) && this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.eventValidated, E.status.notificationSent, E.status.notificationBlocked, E.status.requestOfFundsBlocked, E.status.eventClosed];
        } else if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.eventValidated, E.status.notificationSent, E.status.notificationBlocked, E.status.preAdviseBlocked, E.status.eventClosed];
        }
        break;
      }
      case E.status.preadviseInRepair: {
        if ((this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) && this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.requestOfFundsSent, E.status.preAdviseBlocked, E.status.paymentToBeInput, E.status.preAdviseSent, E.status.eventClosed, E.status.paymentBlocked];
        } else if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.notificationSent, E.status.preAdviseBlocked, E.status.preAdviseSent, E.status.eventClosed];
        }
        break;
      }
      case E.status.paymentInRepair: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.paymentBlocked, E.status.paymentToBeInput, E.status.paymentDone, E.status.eventClosed];
        }
        break;
      }
      case E.status.paymentInitiated: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.eventClosed];
        }
        break;
      }
      case E.status.requestOfFundsInRepair: {
        if ((this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) && this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.notificationSent, E.status.requestOfFundsSent, E.status.requestOfFundsBlocked, E.status.preAdviseBlocked, E.status.paymentBlocked, E.status.eventClosed];
        } else if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.eventValidated, E.status.requestOfFundsSent, E.status.requestOfFundsBlocked, E.status.paymentBlocked, E.status.eventClosed];
        }
        break;
      }
      case E.status.eventValidated: {
        if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg && (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked, E.status.requestOfFundsBlocked, E.status.notificationBlocked];
        } else if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.requestOfFundsBlocked];
        } else if (this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
          this.enableEventStatus = true;
          this.eventStatusDropDownValues = [eventStatus, E.status.preAdviseBlocked, E.status.notificationBlocked];
        }
      }
    }
    this.eventDetailFormService.setEventStatusFields(this.enableEventStatus);
  }

  public get enableModifyEvent(): boolean {
    const eventStatus = this.formService.rawValue('eventStatus');
    this.instrumentStatus = this.formService.rawValue('instrumentStatus')!;
    return eventStatus && this.instrumentStatus === 'LIVE' ? !this.disableModifyForEvents.includes(eventStatus.valueDescription!) : false;
  }

  public get multiplePmtCurrenciesExists(): boolean {
    const multiplePmtCurrencies = this.formService.rawValue('multiplePmtCurrencies');
    return multiplePmtCurrencies ? true : false;
  }

  public get isEditMode(): boolean {
    return this.eventDetailFormService.formMode === 'edit';
  }

  public get isEventIntrType(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType !== E.eventTypes.INTR;
  }

  public get displayRedemptionField(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType ? !this.displayRedemptionFieldsInTypes.includes(eventType) : false;
  }

  public get displayBeginEndDate(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType !== E.eventTypes.INTR;
  }

  public get displayExDate(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType !== E.eventTypes.DVCA;
  }

  public get displayDenominationDetails(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType ? !this.denominationEventTypes.includes(eventType) : false;
  }

  public get isIntrRedmMcalDvcaType(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType ? this.poolFactorAndPartialRedemption.includes(eventType) : false;
  }

  public get isEventDvcaType(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType === E.eventTypes.DVCA;
  }

  public get isEventExchCertType(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType === E.eventTypes.EXCH || eventType === E.eventTypes.CERT;
  }

  public get idRedmTypeDT(): boolean {
    const redmType = this.formService.rawValue('redemptionType')?.valueDescription;
    return redmType === E.redemptionType.C;
  }

  public get displayRedemtionUnit(): boolean {
    const tradingType = this.formService.rawValue('tradingMethodIdentifier');
    return tradingType !== E.tradingMethod.UNIT;
  }

  public get displayRedemtionPercent(): boolean {
    const tradingType = this.formService.rawValue('tradingMethodIdentifier');
    return tradingType !== E.tradingMethod.NOMINAL;
  }

  public get displayCancelButton(): boolean {
    const eventStatus = this.formService.rawValue('eventStatus');
    return eventStatus ? !this.disableCancelForStatus.includes(eventStatus.valueDescription!) : false;
  }

  public get displaySendToValidation(): boolean {
    const eventStatus = this.eventDetailFormService.rawValue().eventStatus;
    return eventStatus ? this.displaySendToValidationForStatus.includes(eventStatus.valueDescription!) && !this.isEditMode : false;
  }

  public get displayReleasePayment(): boolean {
    const eventStatus = this.eventDetailFormService.rawValue().eventStatus?.valueDescription;
    if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
      if (
        (eventStatus === E.status.requestOfFundsSent && this.commonDepLeiCode !== E.leiCodeSocieteGeneraleLuxembourg && this.commonServiceProviderLeiCode !== E.leiCodeSocieteGeneraleLuxembourg) ||
        (eventStatus === E.status.preAdviseSent && (this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg)) ||
        eventStatus === E.status.paymentToBeInput ||
        eventStatus === E.status.paymentBlocked
      ) {
        return true;
      }
    }
    return false;
  }

  // To be implemented in next release after release/1.6.3
  // public get hideRoleBasedFields(): boolean {
  //   return this.isInternationalISIN ? this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg : false;
  // }

  dateModificationAlert() {
    const notificationDate = this.formService.rawValue('notificationDate')!;
    const reqFundDate = this.formService.rawValue('reqFundDate');
    const preAdviceDate = this.formService.rawValue('preAdvDate');
    const valueDate = this.formService.rawValue('valueDate');
    this.reqFundsDateModificationAlert = false;
    this.preAdviseReqOfFundModificationAlert = false;
    this.preAdviseNotificationModificationAlert = false;
    this.valueDateModificationAlert = false;
    if (reqFundDate) {
      this.reqFundsDateModificationAlert = notificationDate > reqFundDate;
      this.valueDateModificationAlert = valueDate ? valueDate < reqFundDate : false;
      if (preAdviceDate && preAdviceDate < reqFundDate) {
        this.preAdviseReqOfFundModificationAlert = true;
      }
    } else if (notificationDate && preAdviceDate && preAdviceDate < notificationDate) {
      this.preAdviseNotificationModificationAlert = true;
    }

    if (valueDate) {
      if (notificationDate && !this.valueDateModificationAlert) {
        this.valueDateModificationAlert = valueDate < notificationDate;
      }
      if (preAdviceDate && !this.valueDateModificationAlert) {
        this.valueDateModificationAlert = valueDate < preAdviceDate;
      }
    }
    this.disablingSaveButton();
  }

  recordValueDateModify() {
    const recordDate = this.formService.rawValue('recordDate');
    const valueDate = this.formService.rawValue('valueDate');
    this.recordValueDateAlert = false;
    if (this.isInternationalISIN && recordDate && valueDate && valueDate < recordDate) {
      this.recordValueDateAlert = true;
    }
  }

  beginEndDateModificationAlert() {
    const beginDate = toFilterDateString(this.formService.rawValue('beginDate')!);
    const endDate = toFilterDateString(this.formService.rawValue('endDate')!);
    if (beginDate && endDate && endDate < beginDate) {
      this.beginEndDateAlert = true;
    } else {
      this.beginEndDateAlert = false;
    }
    this.disablingSaveButton();
  }

  private disablingSaveButton() {
    this.disableSaveButton = this.reqFundsDateModificationAlert || this.preAdviseReqOfFundModificationAlert || this.preAdviseNotificationModificationAlert || this.beginEndDateAlert;
  }

  displayRedemtionTypeBasedFields() {
    const redemptionType = this.formService.rawValue('redemptionType');
    this.eventDetailFormService.enableRedemtionTypeFields(redemptionType?.valueId);
  }

  onEditClick() {
    this.router.navigate(routeToEvent(this.eventId, 'edit'));
  }

  onBackClick() {
    if (this.locationService.navgationHistories.length >= 1) {
      const currentUrl = this.locationService.getCurrentUrl();
      if (currentUrl.includes('edit')) {
        this.router.navigateByUrl(this.locationService.getBackUrl());
      } else {
        const backUrl = this.locationService.navgationHistories.reverse().find(x => x.includes('query'));
        this.router.navigateByUrl(backUrl!);
        return;
      }
    } else {
      this.router.navigate(routeToEvent());
    }
  }

  onSendToValidateClick() {
    const eventType = this.eventDetailFormService.rawValue().eventType;
    const newPoolFactor = this.eventDetailFormService.rawValue().newPoolFactor;
    this.eventDetailFormService.setFormMode('edit');
    const isFormValid = this.eventDetailFormService.valid;
    this.eventDetailFormService.setFormMode('consult');
    const eventId = this.eventDetailFormService.formGroup.value.eventId;
    if (eventType === E.eventTypes.EXCH) {
      if (this.positionData[0].clsSemeReference || this.positionData[0].eocSemeReference) {
        this.eventService.updateEventStatus(Number(eventId), 32).subscribe(result => {
          if (result.response?.[eventId] === 'Successfully Updated') {
            this.displayValidateAndReject = false;
            const eventStatus = this.formGroup.get('eventStatus');
            const status: IpaTypeModel = {
              valueDescription: E.status.exchangeToValidate,
              valueId: 32
            };
            eventStatus?.setValue(status);
            this.toastManagerService.toastSuccess('toasts.events.sendToValidate.title', 'toasts.events.sendToValidate.message', undefined, result);
          } else {
            this.toastManagerService.toastWarning('toasts.events.sendToValidateFailed.title', 'toasts.events.sendToValidateFailed.message');
          }
        });
      } else {
        this.router.navigate(routeToEvent(this.eventId, 'edit'));
        this.toastManagerService.toastWarning('toasts.events.mandatoryExchDetails.title', 'toasts.events.mandatoryExchDetails.message');
      }
    } else if (eventType === E.eventTypes.CERT) {
      if (this.positionData[0].clsSemeReference || this.positionData[0].eocSemeReference) {
        this.eventService.updateEventStatus(Number(eventId), 33).subscribe(result => {
          if (result.response?.[eventId] === 'Successfully Updated') {
            this.displayValidateAndReject = false;
            const eventStatus = this.formGroup.get('eventStatus');
            const status: IpaTypeModel = {
              valueDescription: E.status.certificationToValidate,
              valueId: 33
            };
            eventStatus?.setValue(status);
            this.toastManagerService.toastSuccess('toasts.events.sendToValidate.title', 'toasts.events.sendToValidate.message', undefined, result);
          } else {
            this.toastManagerService.toastWarning('toasts.events.sendToValidateFailed.title', 'toasts.events.sendToValidateFailed.message');
          }
        });
      } else {
        this.router.navigate(routeToEvent(this.eventId, 'edit'));
        this.toastManagerService.toastWarning('toasts.events.mandatoryCertDetails.title', 'toasts.events.mandatoryCertDetails.message');
      }
    } else if (isFormValid) {
      if (eventType === E.eventTypes.PRED && newPoolFactor === undefined) {
        this.toastManagerService.toastWarning('toasts.events.mandatoryNewPoolFactor.title', 'toasts.events.mandatoryNewPoolFactor.message', undefined);
        return;
      }
      this.eventService.updateEventStatus(Number(eventId), 9).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.eventToBeValidated,
            valueId: 9
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastSuccess('toasts.events.sendToValidate.title', 'toasts.events.sendToValidate.message', undefined, result);
        }
      });
    } else {
      this.router.navigate(routeToEvent(this.eventId, 'edit'));
      this.toastManagerService.toastWarning('toasts.instruments.invalid-form.title', 'toasts.instruments.invalid-form.message');
    }
  }

  onValidateClick() {
    const eventType = this.eventDetailFormService.rawValue().eventType;
    const eventId = this.eventDetailFormService.rawValue().eventId;
    if (eventType === E.eventTypes.EXCH || eventType === E.eventTypes.CERT) {
      this.eventService.updateEventStatus(Number(eventId), 34).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.confirmationDone,
            valueId: 34
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastSuccess('toasts.events.validated.title', 'toasts.events.validated.message', undefined, result);
        }
      });
    } else {
      this.eventService.updateEventStatus(Number(eventId), 8).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.eventValidated,
            valueId: 8
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastSuccess('toasts.events.validated.title', 'toasts.events.validated.message', undefined, result);
        }
      });
    }
  }

  onRejectClick() {
    const eventId = this.eventDetailFormService.formGroup.value.eventId;
    if (this.eventDetailFormService.rawValue().eventType === E.eventTypes.EXCH) {
      this.eventService.updateEventStatus(Number(eventId), 25).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.exchangeToDo,
            valueId: 25
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastWarning('toasts.events.rejected.title', 'toasts.events.rejected.message', undefined, result);
        }
      });
    } else if (this.eventDetailFormService.rawValue().eventType === E.eventTypes.CERT) {
      this.eventService.updateEventStatus(Number(eventId), 31).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.certificationToDo,
            valueId: 31
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastWarning('toasts.events.rejected.title', 'toasts.events.rejected.message', undefined, result);
        }
      });
    } else {
      this.eventService.updateEventStatus(Number(eventId), 7).subscribe(result => {
        if (result) {
          this.displayValidateAndReject = false;
          const eventStatus = this.formGroup.get('eventStatus');
          const status: IpaTypeModel = {
            valueDescription: E.status.valuationInRepair,
            valueId: 7
          };
          eventStatus?.setValue(status);
          this.toastManagerService.toastWarning('toasts.events.rejected.title', 'toasts.events.rejected.message', undefined, result);
        }
      });
    }
  }

  onCancelEventClick() {
    const modal = this.eventModalsService.openEventCancelModal();
    const eventId = this.eventDetailFormService.formGroup.value.eventId;

    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.confirmCancelEvent(eventId);
    });
  }

  confirmCancelEvent(eventId: any[]) {
    this.eventService.updateEventStatus(Number(eventId), 5).subscribe(result => {
      if (result) {
        const eventStatus = this.formGroup.get('eventStatus');
        const status: IpaTypeModel = {
          valueDescription: E.status.cancelled,
          valueId: 5
        };
        eventStatus?.setValue(status);
        this.toastManagerService.toastSuccess('toasts.events.cancel.title', 'toasts.events.cancel.message', undefined, result);
      }
    });
  }

  private highlightMismatchedDataForREDMAndMCAL(event: EventDetailModel, valuation: ValuationDetailModel) {
    if (event.redemptionType?.valueDescription !== valuation.redemptionType?.valueDescription) {
      this.redmTypeMismatched = this.mismatchColor;
    }
    if (event.tradingMethodIdentifier === 'UNIT') {
      if (Number(event.eventRedemptionUnit?.replace(/,/gi, '')) !== Number(valuation.redemptionRate?.replace(/,/gi, ''))) {
        this.redmRateMismatched = this.mismatchColor;
      } else {
        this.redmRateMismatched = '';
      }
    } else {
      if (Number(event.eventRedemptionPrice?.replace(/,/gi, '')) !== Number(valuation.redemptionRate?.replace(/,/gi, ''))) {
        this.redmRateMismatched = this.mismatchColor;
      } else {
        this.redmRateMismatched = '';
      }
    }
    if (event.denominationAsString !== valuation.denomination) {
      this.denominationValueMismatched = this.mismatchColor;
    }
    if (event.paymentCurrency !== valuation.paymentCcy) {
      this.denominationCcyMismatched = this.mismatchColor;
    }
  }

  private highlightMismatchedDataForINTR(event: EventDetailModel, valuation: ValuationDetailModel) {
    const compatibleDayCountFraction = ['30A/360', '30E/360'];
    if (!(compatibleDayCountFraction.includes(event.dayCountFraction!) && compatibleDayCountFraction.includes(valuation.dayCountFraction!))) {
      if (event.dayCountFraction !== valuation.dayCountFraction) {
        this.dcfColorMismatched = this.mismatchColor;
      }
    }

    if (event.denominationAsString !== valuation.denomination) {
      this.denominationValueMismatched = this.mismatchColor;
    }

    if (event.beginDate !== valuation.startDate) {
      this.startDateValueMismatched = this.mismatchColor;
    }

    if (event.endDate !== valuation.endDate) {
      this.endDateValueMismatched = this.mismatchColor;
    }

    if (event.amountPerDenomination !== valuation.amountPerDenomination) {
      this.amountPerDenominationValueMismatched = this.mismatchColor;
    }

    if (event.paymentCurrency !== valuation.paymentCcy) {
      this.denominationCcyMismatched = this.mismatchColor;
    }
  }

  private getRedmMcalValuesFromValuation(eventDetail: EventDetailModel, valuationData: ValuationDetailModel) {
    if (!eventDetail.amountPerDenomination && valuationData.amountPerDenomination) {
      this.eventDetailFormService.patchField('amountPerDenomination', valuationData.amountPerDenomination);
    }
    if (!eventDetail.forexRate) {
      this.eventDetailFormService.patchField('forexRate', valuationData.forexRate);
    }
    if (!eventDetail.strikePrice) {
      this.eventDetailFormService.patchField('strikePrice', valuationData.strikePrice);
    }
    if (!eventDetail.underlyingIsin) {
      this.eventDetailFormService.patchField('underlyingIsin', valuationData.underlyingIsin);
    }
    if (!eventDetail.underlyingDenomQty) {
      this.eventDetailFormService.patchField('underlyingDenomQty', valuationData.underlyingDenomQty);
    }
    if (!eventDetail.redemptionType?.valueDescription) {
      this.eventDetailFormService.patchField('redemptionType', valuationData.redemptionType);
    }
    if (!eventDetail.eventRedemptionPrice) {
      this.eventDetailFormService.patchField('eventRedemptionPrice', valuationData.redemptionRate?.toString());
    }
    if (!eventDetail.eventRedemptionUnit) {
      this.eventDetailFormService.patchField('eventRedemptionUnit', valuationData.redemptionRate?.toString());
    }
  }

  private getIntrValuesFromValuation(eventDetail: EventDetailModel, valuationData: ValuationDetailModel) {
    if (!eventDetail.amountPerDenomination && valuationData.amountPerDenomination) {
      this.eventDetailFormService.patchField('amountPerDenomination', valuationData.amountPerDenomination);
    }
    if (!eventDetail.forexRate) {
      this.eventDetailFormService.patchField('forexRate', valuationData.forexRate);
    }
    if (!eventDetail.interestRate) {
      this.eventDetailFormService.patchField('interestRate', valuationData.interestRate);
    }
  }

  private setRoleBasedMandatoryFields() {
    this.addValidatorsForcommonDepType();
    this.addValidatorsForlistingType();
    this.addValidatorsForpayingType();
    const commonServiceProvider = this.formGroup.get('commonServiceProvider');
    if (commonServiceProvider?.value?.leiCode === E.leiCodeSocieteGeneraleLuxembourg) {
      commonServiceProvider?.setValue(commonServiceProvider.value.name);
      this.isCspSgLux = true;
    }
    const commonDepositary = this.formGroup.get('commonDepositary');
    if (commonDepositary?.value) {
      commonDepositary?.setValue(commonDepositary.value.name);
    }
    const listingAgent = this.formGroup.get('listingAgent');
    if (listingAgent?.value) {
      listingAgent?.setValue(listingAgent.value.name);
    }
    const principalPayingAgent = this.formGroup.get('principalPayingAgent');
    if (principalPayingAgent?.value) {
      principalPayingAgent?.setValue(principalPayingAgent.value.name);
    }
  }

  private addValidatorsForcommonDepType() {
    this.commonDepLeiCode = this.formService.rawValue('commonDepositary')?.leiCode;
    this.commonServiceProviderLeiCode = this.formService.rawValue('commonServiceProvider')?.leiCode;
    if (this.commonDepLeiCode === E.leiCodeSocieteGeneraleLuxembourg || this.commonServiceProviderLeiCode === E.leiCodeSocieteGeneraleLuxembourg) {
      this.eventDetailFormService.addcommonDepTypeValidators();
    }
  }

  private addValidatorsForlistingType() {
    this.listingType = this.formService.rawValue('listingAgent')?.leiCode;
    if (this.listingType === E.leiCodeSocieteGeneraleLuxembourg) {
      this.eventDetailFormService.addListingTypeValidators();
    }
  }

  private addValidatorsForpayingType() {
    this.payingType = this.formService.rawValue('principalPayingAgent')?.leiCode;
    if (this.payingType === E.leiCodeSocieteGeneraleLuxembourg) {
      this.eventDetailFormService.addPayingTypeValidators();
    }
  }

  ngOnDestroy(): void {
    this.eventDetailFormService.setFormMode('consult');
    this.eventDetailFormService.unlockAllFieldsExcept();
    this.eventDetailFormService.clearValidators();
    this.eventDetailFormService.addAmountPerDenomValidators();
    this.eventDetailFormService.clearInitialValue();
    this.eventDetailFormService.reset();
    this.auditDetailFormService.reset();
    this.shutdown$.next();
  }
}
